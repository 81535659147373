/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerDataWithSms } from '@adac/core-model';
import { useEffect } from 'react';

export const mapCustomerDataToBackend = ({
  title,
  customerFirstName,
  customerFamilyName,
  customerPhone,
  customerAddressZip,
  customerAddressCity,
  customerAddressStreet,
  customerAddressNumber,
  commissioningReference,
  productType,
  productNumber,
  damageNumber,
  latitude,
  longitude,
  companyId,
  complexity,
  receiveStatusViaSMS,
  description,
  // infoCaseType,
}: CustomerDataWithSms) => ({
  commissioningReference,
  customerAddressZip: `${customerAddressZip}`,
  description: `${description}`,
  title,
  customerFirstName,
  customerFamilyName,
  customerPhone,
  customerAddressCity,
  customerAddressStreet,
  customerAddressNumber,
  companyId,
  productType,
  productNumber,
  damageNumber,
  latitude,
  longitude,
  complexity,
  receiveStatusViaSMS,
  differentBillingAddress: {
    title,
    customerFirstName,
    customerFamilyName,
    customerAddressZip: `${customerAddressZip}`,
    customerAddressCity,
    customerAddressStreet,
    customerAddressNumber,
  },
});

const clearCaseOnLeavingPage = (history: any, saveCustomerData: (data: CustomerDataWithSms | null) => void, exceptionUrl?: string) => {
  if (history.action === 'POP' || (
    history.action === 'PUSH'
    && history.location.pathname !== exceptionUrl
  )) {
    saveCustomerData(null);
  }
};

export const useClearCaseOnLeavingPage = (history: any, saveCustomerData: (data: CustomerDataWithSms | null) => void, exceptionUrl?: string) => {
  // this only needs to run on component unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearCaseOnLeavingPage(history, saveCustomerData, exceptionUrl), []);
};
