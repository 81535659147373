/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';

import {
  FormikHelpers as FormikActions,
} from 'formik';

import {
  Text,
  Button,
  ButtonTitle,
  View,
  ChangePasswordFormValues,
  LogoutButton,
  Title,
  useTokenMatcher,
  useAsyncComponent,
  ConfirmTokenForm,
  useFormikAuth,
  ErrorConfirmFunc,
  useGetMe,
  SubTitle,
  ChangePhoneFormValues,
} from '@adac/core-view';

import {
  __, changePasswordCommit, changePasswordStart, confirmToken,
  changePhoneCommit,
} from '@adac/core-model';

import { Card } from '@material-ui/core';
import CustomCard, { CardContent } from '../common/CustomCard';


import StoresContext from '../../stores';
import { FormProps } from '../common/react-admin/interfaces';
import { ChangePassword } from './ChangePassword';
import { ChangePhone } from './ChangePhone';
import { SimpleHeader } from '../common/Header';
import { UserMetaInfo } from './UserMetaInfo';





const ButtonContainerStyles = styled(CardContent).attrs(() => ({ as: 'fieldset' }))``;

export const LayoutStyles = styled(CustomCard)`
  section {
    padding: 24px;
  }
  
  fieldset {
    padding: 24px;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
  }
`;

const LogoutButtonStyles = styled(View)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;



const Profile = (props: FormProps<null>) => {
  const {
    tokenKey, tokenValue, setTokenKey, setTokenValue, state,
  } = useTokenMatcher();
  const { auth: { token: jwt, deleteToken } } = useContext(StoresContext);
  const { user } = useGetMe(jwt);
  const goTo = props.history.push;
  const goBack = props.history.goBack;

  const submitChangePassword = async (values: ChangePasswordFormValues, actions: FormikActions<ChangePasswordFormValues>) => {
    try {
      await changePasswordCommit(values.currentPassword, values.newPassword, values.confirmPassword, tokenKey, tokenValue, `${jwt}`);
      goTo('/', { prefixed: false });
      props.showNotification(__('Password changed'), 'success');
    } catch (error) {
      props.showNotification(__((error as Error)?.message), 'error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitChangePhone = async (values: ChangePhoneFormValues, actions: FormikActions<ChangePhoneFormValues>) => {
    try {
      await changePhoneCommit(values.phone, tokenKey, tokenValue, `${jwt}`);
      goTo('/', { prefixed: false });
      props.showNotification(__('Phone changed'), 'success');
    } catch (error) {
      props.showNotification(__((error as Error)?.message), 'error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { Component: StartChangeButton } = useAsyncComponent({
    onClick: async () => {
      const token = await changePasswordStart(`${jwt}`);
      setTokenKey(token);
    },
  });

  const onConfirmError: ErrorConfirmFunc = () => {
    props.showNotification(__('Unsuccessfull error on changing password attempt'), 'error');
  };

  const { onSubmit: onConfirmSubmit } = useFormikAuth(confirmToken, onConfirmError, (token) => setTokenValue(token));


  if (state === 'validated') {
    return (
      <Card>
        <SimpleHeader {...props} title={__('Your Profile')} />

        <ChangePhone {...props} onSubmit={submitChangePhone} />
        <ChangePassword {...props} onSubmit={submitChangePassword} />

        <LogoutButtonStyles>
          <LogoutButton onClick={() => deleteToken()} />
        </LogoutButtonStyles>

      </Card>
    );
  }

  if (state === 'validating') {
    return (
      <LayoutStyles>
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirmSubmit} onCancel={() => setTokenKey('')}>
          <Title>{__('Sending confirmation token value')}</Title>
          <br />
          <br />
          <Text>{__('Please enter confirmation token value')}</Text>
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <SimpleHeader {...props} title={__('Your Profile')} />
      {user && <UserMetaInfo {...user} />}

      <SubTitle>{__('Click here to change your login details')}</SubTitle>
      <Text>{__('Sending confirmation token value')}</Text>

      <ButtonContainerStyles>
        <StartChangeButton cta type="submit" title={__('Confirm')} />
        <Button info type="button" onClick={goBack}>
          <ButtonTitle>{__('Cancel')}</ButtonTitle>
        </Button>
      </ButtonContainerStyles>
    </LayoutStyles>
  );
};


export default connect(null, {
  showNotification: showNotificationAction,
})(Profile);
