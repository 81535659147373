import { isValidMobilePhoneNumber, UserRole, getPasswordRegex } from '@adac/core-model';
import { passwordErrorMessage } from '@adac/core-view';
import * as yup from 'yup';

export const UserFormSchema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.required(),
  }),
  phone: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.required().test(
      'validPhone',
      'phone has to start with plus',
      phone => isValidMobilePhoneNumber(phone),
    ),
  }),
  password: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.oneOf([yup.ref('confirmPassword')]).matches(getPasswordRegex(), passwordErrorMessage),
  }),
  confirmPassword: yup.string().when('role', {
    is: UserRole.API,
    then: schema => schema.nullable(),
    otherwise: schema => schema.oneOf([yup.ref('password')]),
  }),
});

export const CreateUserFormSchema = UserFormSchema.shape({
  role: yup.string().required(),
});
