/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Edit, getResources, UPDATE, showNotification as showNotificationAction,
} from 'react-admin';
import {
  Formik, FormikHelpers as FormikActions, FormikProps,
} from 'formik';

import { connect } from 'react-redux';
import { extractCompanyAddressObject } from '@adac/core-view';
import {
  __, CompanyFormValues, UserRole, allCompanyTypes,
  CompanyDTO, extractLocationCoordsWithConfidence, getApiRoutes,
} from '@adac/core-model';

import dataProvider from '../../dataProvider';

import { CompanyForm } from './CompanyForm';


import {
  DeleteButton,
} from '../common/DeleteButton';
import { EditCompanySchema } from './CompanyFormSchema';
import { FormProps } from '../common/react-admin/interfaces';


const CompanyEditForm = (raProps: FormProps<CompanyFormValues>) => {
  const company = raProps.record as CompanyDTO;

  const {
    companyAddressZip, companyAddressCity, companyAddressStreet, companyAddressNumber, ...companyData
  } = company;

  const companyAddressProps = {
    companyAddressZip, companyAddressCity, companyAddressStreet, companyAddressNumber,
  };

  const sendUpdateRequest = async (newData: any, setSubmitting: (b: boolean) => void) => {
    try {
      await dataProvider(UPDATE, raProps.resource, { id: company?.id, data: newData });
      setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').companyAdmin.list);
    } catch (error) {
      raProps.showNotification(__(error.toString()), 'warning');
      setSubmitting(false);
    }
  };

  const updateCompany = async (values: CompanyFormValues, actions: FormikActions<CompanyFormValues>) => {
    const newData = {
      contactManagerId: values.contactManagerId,
      activeFrom: values.isScheduled ? values.activeFrom : null,
      activeTo: values.isScheduled ? values.activeTo : null,
      user: {
        username: values.username,
        ...(values.password ? { password: values.password } : {}),
        roles: [UserRole.COMPANY],
      },
      driver: {
        familyName: values.familyName,
        firstName: values.firstName,
        privatePhone: values.privatePhone,
        publicPhone: values.publicPhone,
        validatePhone: values.validatePhone,
      },
    };
    sendUpdateRequest(newData, actions.setSubmitting);
  };

  const setCompanyInactive = async ({ setSubmitting }: FormikProps<CompanyFormValues>) => {
    setSubmitting(true);
    const updatedValues = {
      active: false,
      activeTo: new Date(Date.now()),
      isScheduled: true,
    };
    sendUpdateRequest(updatedValues, setSubmitting);
  };

  return (
    <Formik<CompanyFormValues>
      initialValues={{
        ...companyData,
        ...extractLocationCoordsWithConfidence(companyData),
        ...extractCompanyAddressObject(companyAddressProps),
        isScheduled: !!company?.activeFrom || !!company.activeTo,
        validatePhone: true,
      }}
      validationSchema={EditCompanySchema}
      validateOnMount
      onSubmit={updateCompany}
    >
      {(props: FormikProps<CompanyFormValues>) => (
        <CompanyForm
          companyTypes={allCompanyTypes}
          onCancel={() => raProps.history.push(getApiRoutes(false, '').companyAdmin.list)}
        >
          <DeleteButton
            onClick={() => setCompanyInactive(props)}
            isLoading={props.isSubmitting}
            disabled={props.isSubmitting}
          />
        </CompanyForm>
      )}
    </Formik>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedCompanyEditForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(CompanyEditForm);

export default (raProps: any) => (
  <Edit {...raProps}>
    <ConnectedCompanyEditForm {...raProps} />
  </Edit>
);
