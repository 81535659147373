/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useContext } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';

import {
  withStyles, createStyles, makeStyles,
} from '@material-ui/styles';

import DriveIcon from '@material-ui/icons/DriveEta';
import PeopleIcon from '@material-ui/icons/People';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BallotIcon from '@material-ui/icons/Ballot';
import Spa from '@material-ui/icons/Spa';

import {
  Text, Button, View, Badge,
} from '@adac/core-view';
import { __, UserRole, EscalationData } from '@adac/core-model';

import StoresContext from '../../stores/index';

import {
  USERS_BACKOFFICE,
  CLOSED_CASES,
  OPEN_CASES,
  SND_COMPANIES,
  BENCHMARK,

  HOLIDAYS,
  SND_COMPANY_DRIVERS,
  ADMIN_LOGS,
} from '../../dataProvider/resources';

const ButtonStyles = styled(View)`
  margin: 24px;
`;

const ButtonTitle = styled(Text)`
  font-weight: bold;
  font-size: 1.4rem;
`;

const MenuItemWithBadge = styled(View)`
  position: relative;
`;

const MenuItemBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
`;

const AnimatedMenuItem = styled(View)`
  @keyframes loading {
    0% {
      content: "\\00a0";
    }
    10% {
      content: "\\00a0.";
    }
    20% {
      content: "\\00a0..";
    }
    30% {
      content: "\\00a0...";
    }
    40% {
      content: "\\00a0....";
    }
    50% {
      content: "\\00a0.....";
    }
  }
  &.loading a::after {
    content: "\\00a0";
    animation: loading 2s linear infinite;
  }
`;

// This can't be moved to theme, because theme only supports MUI components, and MenuItemLink comes from react-admin...
const styles = ({ palette }: any) => createStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    color: palette.primary.main,
    opacity: 0.6,
  }, // Style applied to the MenuItem from material-ui
  // active: {
  //   opacity: 1,
  //   borderLeft: `4px solid ${palette.primary.main}`,
  // }, // Style applied when the menu item is the active one
  // icon: {}, // Style applied to the icon
});

const useStyles = makeStyles(({ palette }: any) => ({
  active: {
    opacity: 1,
    borderRadius: 0,
    justifyContent: 'flex-start',
    padding: '16px',
    position: 'relative',
    '&:before': {
      zIndex: 2,
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      bottom: 0,
      width: '4px',
      backgroundColor: palette.primary.main,
    },
  },
}));

const getMenuItemProps = (resource: any, currentResource: any) => {
  let text = resource.name;
  let extraClass = '';
  let icon: JSX.Element | null = null;
  const isSelected = currentResource === resource.name;
  const iconColor = isSelected ? 'secondary' : 'primary';

  switch (resource.name) {
    case SND_COMPANIES:
      text = __('SND Partners');
      icon = <DriveIcon color={iconColor} />;
      break;
    case USERS_BACKOFFICE:
      text = __('User management');
      icon = <PeopleIcon color={iconColor} />;
      break;
    case OPEN_CASES:
      extraClass= 'loading';
      text = __('Open cases');
      icon = <ScheduleIcon color={iconColor} />;
      break;
    case CLOSED_CASES:
      text = __('Closed cases');
      icon = <CheckCircleIcon color={iconColor} />;
      break;
    case HOLIDAYS:
      text = __('Holidays');
      icon = <Spa color={iconColor} />;
      break;
    case BENCHMARK:
      text = __('Statistics');
      icon = <AssessmentIcon color={iconColor} />;
      break;
    case ADMIN_LOGS:
      text = __('Admin logs');
      icon = <BallotIcon color={iconColor} />;
      break;
    default:
      break;
  }

  return { extraClass, text, icon };
};

const getMenuItem = (
  resource: any,
  currentResource: any,
  _classes: string,
  link: string,
  onMenuClick: () => void,
  menuItemClasses: any,
  key?: number,
) => {
  const { text, icon, extraClass } = getMenuItemProps(resource, currentResource);
  return (
    <AnimatedMenuItem
      key={key}
      className={`${extraClass}`}
      // style={{
      //   borderLeft: '4px solid black',
      // }}
    >
      <MenuItemLink
        classes={menuItemClasses}
        leftIcon={icon}
        to={link}
        primaryText={text}
        onClick={onMenuClick}
      />
    </AnimatedMenuItem>
  );
};

const getMenuItems = (
  resource: any,
  index: number,
  currentResource: string,
  classes: string,
  untakenCases: EscalationData[],
  menuItemClasses: any,
  onMenuClick: () => void,
) => {
  const getMenuItemWithBadge = (casesToConsider: EscalationData[], badgeColor?: string) => (
    <MenuItemWithBadge key={index}>
      <MenuItemBadge color={badgeColor}>{casesToConsider.length}</MenuItemBadge>
      {getMenuItem(resource, currentResource, classes, `/${resource.name}`, onMenuClick, menuItemClasses)}
    </MenuItemWithBadge>
  );

  if (resource.name === OPEN_CASES && untakenCases && untakenCases.length > 0) {
    return getMenuItemWithBadge(untakenCases);
  }

  return getMenuItem(
    resource,
    currentResource,
    classes,
    `/${resource.name}`,
    onMenuClick,
    menuItemClasses,
    index,
  );
};

// const MyMenu = ({ resources, onMenuClick, logout }: any) => (
const ADACMenu = ({ untakenCases, ...props }: any) => {
  const {
    classes, resources, onMenuClick, location,
  } = props;

  const menuItemClasses = useStyles();

  const currentResource = location.pathname.replace('/', '');

  const stores = useContext(StoresContext);
  const userRoles = stores.auth.userRole || [];


  const roleCheckedResources = resources.filter((resource: any) => {
    const menusHidden = [SND_COMPANY_DRIVERS];
    if (menusHidden.includes(resource.name)) return false;

    const menusWitRole = {
      [UserRole.MANAGER]: () => true, // enable all
      [UserRole.SYS]: (resource: string) => [OPEN_CASES, CLOSED_CASES, SND_COMPANIES, BENCHMARK, HOLIDAYS].includes(resource),
    };

    const role = userRoles.includes(`${UserRole.MANAGER}`) ? UserRole.MANAGER : UserRole.SYS;

    return menusWitRole[role](resource.name);
  });


  const createCaseAllowed = userRoles.includes(UserRole.MANAGER) && (process.env.NODE_ENV === 'development' || process.env.REACT_APP_SERVER_ENV === 'staging');

  return (
    <>
      {roleCheckedResources.map((resource: any, index: number) => getMenuItems(
        resource,
        index,
        currentResource,
        classes,
        untakenCases,
        menuItemClasses,
        onMenuClick,
      ))}

      {/* <MenuItemLink to="/custom-route" primaryText="Miscellaneous" onClick={onMenuClick} /> */}

      {createCaseAllowed && (
        <ButtonStyles>
          {/* Link is not working, probably because react-admin uses its own react-router-dom */}
          <Button info onClick={() => props.history.push('/newcase')}>
            <ButtonTitle>
              {/* <span role="img" aria-label="warning sign">⚠️</span>  */}
              Neuen Fall anlegen
              {/* <span role="img" aria-label="construction icon">🚧</span> */}
            </ButtonTitle>
          </Button>
        </ButtonStyles>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  resources: getResources(state),
  untakenCases: state.untakenCases,
  failedCases: state.failedCases,
});

export default observer(withStyles(styles)(connect(mapStateToProps)(withRouter(ADACMenu))));
