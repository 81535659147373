/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Edit, getResources, DELETE, UPDATE, showNotification as showNotificationAction,
} from 'react-admin';
import {
  Formik, FormikHelpers as FormikActions,
  FormikProps,
} from 'formik';
import { connect } from 'react-redux';

import { getApiRoutes, __ } from '@adac/core-model';

import dataProvider from '../../dataProvider';

import {
  DeleteButton,
} from '../common/DeleteButton';
import { HolidayForm, HolidaySchema } from './HolidayForm';
import { HolidayFormValues } from './HolidayFormValues';
import { SimpleRegionSelector } from './RegionSelector';
import { FormProps } from '../common/react-admin/interfaces';
import { getRegionIdsForRegions } from './HolidayCreate';
import { useRegionList } from '../../dataProvider/useRegionList';

const HolidayEditForm = (raProps: FormProps) => {
  const holiday = raProps.record;
  const { regions } = useRegionList();

  const deleteHoliday = async ({ setSubmitting }: FormikProps<HolidayFormValues>) => {
    setSubmitting(true);
    try {
      await dataProvider(DELETE, raProps.resource, { id: holiday.id });
      raProps.history.push(getApiRoutes(false, '').backoffice.holiday.list);
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  const initialRegionName = regions.find(region => region.id === (raProps.record.regionId))?.name;

  const onSubmit = async (values: HolidayFormValues, actions: FormikActions<HolidayFormValues>) => {
    const editedHoliday = {
      id: values.id,
      day: values.day,
      month: values.month,
      year: values.year || null,
      name: values.name || null,
      regionId: getRegionIdsForRegions(regions, values.regionNames)[0],
    };
    try {
      await dataProvider(UPDATE, raProps.resource, { id: holiday.id, data: editedHoliday });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').backoffice.holiday.list);
    } catch (error) {
      raProps.showNotification(__(error.toString()), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  const initialValues = {
    ...holiday,
    year: typeof holiday.year === 'string' ? '' : holiday.year,
    regionNames: [initialRegionName || __('ALL REGION')],
  };

  return (
    <Formik
      key={initialRegionName}
      initialValues={initialValues}
      validationSchema={HolidaySchema}
      isInitialValid={holiday.id}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<HolidayFormValues>) => (
        <HolidayForm
          holiday={holiday}
          onCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.holiday.list)}
          regionSelectorComponent={<SimpleRegionSelector />}
        >
          <DeleteButton
            disabled={props.isSubmitting}
            isLoading={props.isSubmitting}
            onClick={() => deleteHoliday(props)}
            title={__('Delete holiday')}
          />
        </HolidayForm>
      )}
    </Formik>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedHolidayEditForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(HolidayEditForm);

export default (raProps: any) => (
  <Edit {...raProps}>
    <ConnectedHolidayEditForm {...raProps} />
  </Edit>
);
