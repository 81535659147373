/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Admin, Resource, Layout, showNotification as showNotificationAction,
} from 'react-admin';

import { ThemeProvider } from '@material-ui/styles';
import { Route } from 'react-router';
import { View } from '@adac/core-view';


import styled from 'styled-components';
import { connect } from 'react-redux';
import { observer } from 'mobx-react';
import {
  USERS_BACKOFFICE,
  CLOSED_CASES,
  OPEN_CASES,
  SND_COMPANIES,
  BENCHMARK,
  HOLIDAYS,
  SND_COMPANY_DRIVERS,
  ADMIN_LOGS,
} from '../../dataProvider/resources';

import Closing from '../cancellation/Closing';

import theme from '../../theme/materialTheme';
import setClosingCaseAction from '../../actions/setClosingCase';

import ADACAppBar from './ADACAppBar';
import ADACMenu from './ADACMenu';

import dataProvider from '../../dataProvider';

import CompanyList from '../snd/CompanyList';
import CompanyEdit from '../snd/CompanyEdit';
import CompanyCreate from '../snd/CompanyCreate';

import UserList from '../users/UserList';
import UserEdit from '../users/UserEdit';
import UserCreate from '../users/UserCreate';

import OpenCaseList from '../cases/OpenCaseList';
import ClosedCaseList from '../cases/ClosedCaseList';

import HolidayList from '../holiday/HolidayList';
import HolidayCreate from '../holiday/HolidayCreate';
import HolidayEdit from '../holiday/HolidayEdit';

import StatisticsPage from '../statistics/StatisticsPage';
import AdminLogList from '../adminLog/AdminLogList';

import NewCase from '../cases/NewCase';
import Offer from '../cases/NewCasePreview';
import NewCaseDone from '../cases/NewCaseDone';
import CaseStatus from '../cases/CaseStatus';

import customerDataReducer from '../../reducers/customerDataReducer';

import Profile from '../users/Profile';

// import FailedCaseListener from '../cases/FailedCaseListener';

import browserNotificationReducer from '../../reducers/browserNotificationReducer';
import closingCaseReducer from '../../reducers/closingCaseReducer';
import DriverList from '../snd/DriverList';
import DriverEdit from '../snd/DriverEdit';
import DriverCreate from '../snd/DriverCreate';
import NotificationSettingsEdit from '../snd/NotificationSettingsEdit';
import AdminLogDetails from '../adminLog/AdminLogDetails';


// NOTE: should be fixing better this for https://trello.com/c/AyBa6UfS/1969-fix-company-driver-edit-form-on-adac-sit-staging-server
const DirtyMUILayoutFix = styled(View)`
  & main > * {
    position: relative !important;
    opacity: 1 !important;
    padding: 24px !important;
    padding-left: 5px !important;
    height: max-height !important;
  }
`;

const ADACLayout = (props: any) => (
  <DirtyMUILayoutFix>
    <Layout
      {...props}
      appBar={ADACAppBar}
      menu={ADACMenu}
      style={{
        maxWidth: 'unset',
      }}
      // notification={MyNotification}
    />
  </DirtyMUILayoutFix>
);

const ClosingPopupForm = () => {
  const mapStateToProps = (state: any) => ({
    on: !!state.closingCase,
    currentCase: state.closingCase,
  });
  const actionsToMapToProps = {
    setClosingCase: setClosingCaseAction,
    showNotification: showNotificationAction,
  };

  const ConnectedClosing = connect(mapStateToProps, actionsToMapToProps)(Closing);
  return <ConnectedClosing />;
};


const renderDevelopmentCaseRoutes = () => ((process.env.NODE_ENV === 'development' || process.env.REACT_APP_SERVER_ENV === 'staging') ? [ // TODO: we need these components later for staging & development, so we need a better identification.
  <Route
    path="/newcase"
    component={NewCase}
    exact
  />,
  <Route
    path="/newcase/offer"
    component={Offer}
    exact
  />,
  <Route
    path="/newcase/done"
    component={NewCaseDone}
    exact
  />,
]: []);

export default observer(() => (
  <ThemeProvider theme={{
    ...theme,
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  }}
  >
    <Admin
      theme={theme}
      appLayout={ADACLayout}
      dataProvider={dataProvider}
      customRoutes={[
        ...renderDevelopmentCaseRoutes(),
        <Route
          path="/profile"
          component={Profile}
          exact
        />,
        <Route
          path="/case-status/:caseId"
          component={CaseStatus}
          exact
        />,
        <Route
          path="/admin/backoffice/snd-companies/notifications/:companyId"
          component={NotificationSettingsEdit}
          exact
        />,
      ]}
      customReducers={{
        customerData: customerDataReducer,
        browserNotification: browserNotificationReducer,
        closingCase: closingCaseReducer,
      }}
    >
      <Resource name={OPEN_CASES} list={OpenCaseList} />
      <Resource name={CLOSED_CASES} list={ClosedCaseList} />
      <Resource name={SND_COMPANY_DRIVERS} list={DriverList} edit={DriverEdit} create={DriverCreate} />
      <Resource name={SND_COMPANIES} list={CompanyList} create={CompanyCreate} edit={CompanyEdit} />
      <Resource name={HOLIDAYS} list={HolidayList} create={HolidayCreate} edit={HolidayEdit} />
      <ClosingPopupForm />
      <Resource name={USERS_BACKOFFICE} list={UserList} edit={UserEdit} create={UserCreate} />
      <Resource name={BENCHMARK} list={StatisticsPage} />
      <Resource name={ADMIN_LOGS} list={AdminLogList} show={AdminLogDetails} />

    </Admin>
  </ThemeProvider>
));
