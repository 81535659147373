import { getApiRoutes, request, AdminLogRecord } from '@adac/core-model';
import { DateFilterProps } from '../components/statistics/FilterForm';
import { getHeader } from './requestCreators';


// NOTE: CRA develpoment server has 2 minute timeout anyway https://github.com/axios/axios/issues/1873#issuecomment-617087449
const timeout = 300 * 1000; // 5 minutes


export interface ReportUrl {
  url: string;
}

export const getFullReportCSVUrl = async (params: string[]) => request<ReportUrl, DateFilterProps>(getApiRoutes().statistics.full(params), undefined, getHeader(), undefined, undefined, timeout);

export const getPreviousFullReportUrls = async (userId: number) => request<AdminLogRecord[]>(getApiRoutes().backoffice.adminLog.getLatest('report', userId), undefined, getHeader());

// export const getEstimationsCSVUrl = async () => request<ReportUrl>(getApiRoutes().statistics.timeSuggestions, undefined, getHeader(), undefined, undefined, timeout);
