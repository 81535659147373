/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  // DateInput,
  Filter,
  // NullableBooleanInput,
  TextInput,
} from 'react-admin';
import { __ } from '@adac/core-model';

export default (props: any) => (
  <Filter {...props}>
    <TextInput label={__('User id')} source="id" allowEmpty />
    <TextInput label={__('Username')} source="username" allowEmpty />
    <TextInput label={__('Name')} source="name" allowEmpty />
    {/* <TextInput label={__('Status')} source="status" allowEmpty /> */}
    {/* <TextInput label={__('Role')} source="role" allowEmpty /> */}
  </Filter>
);
