import React, { useContext, useState, useEffect } from 'react';
import {
  Formik, Form, FormikProps,
  FormikHelpers as FormikActions,
} from 'formik';
import {
  Button,
  ButtonTitle, NetworkLoader,
  LoadingSpinner,
  ChangeMFAPhoneFields,
  ChangePhoneSchema,
  ChangePhoneFormValues,
  SubTitle,
} from '@adac/core-view';
import { getBackofficeUser, BackofficeUser, __ } from '@adac/core-model';
import StoresContext from '../../stores';
import {
  LayoutStyles,
} from './Profile';
import { FormProps } from '../common/react-admin/interfaces';
import CardContent from '../snd/CardContent';


export interface ChangePhoneProps extends FormProps{
  onSubmit: (values: ChangePhoneFormValues, actions: FormikActions<any>) => void;
}
export const ChangePhone = ({ onSubmit, ...raProps }: ChangePhoneProps) => {
  const initialValues = {
    phone: '',
  };

  const stores = useContext(StoresContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<BackofficeUser | null>(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const me = await getBackofficeUser(stores.auth.token as string);
        setUser(me);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutStyles>
      <NetworkLoader
        loading={loading}
        loadingView={<LoadingSpinner>{__('Loading')}</LoadingSpinner>}
      >
        <Formik
          initialValues={{ ...initialValues, ...user }}
          validationSchema={ChangePhoneSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }: FormikProps<ChangePhoneFormValues>) => (
            <Form>
              <SubTitle>{__('Login')}</SubTitle>

              <ChangeMFAPhoneFields />

              <CardContent>
                <fieldset>
                  <Button info type="button" onClick={() => raProps.history.goBack()}>
                    <ButtonTitle>{__('Cancel')}</ButtonTitle>
                  </Button>
                  <Button
                    cta
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    title={__('Save')}
                  />
                </fieldset>
              </CardContent>
            </Form>
          )}
        </Formik>
      </NetworkLoader>
    </LayoutStyles>
  );
};
