import React from 'react';
import {
  Form, Field, FieldProps,
  useFormikContext,
} from 'formik';
import {
  TextField as MuiTextField, MenuItem,
} from '@material-ui/core';

import {
  TextField, Button, ColumnLayout, FullWidthLayout, ButtonTitle,
  ErrorMessage,
  ChangeMFAPhoneFields,
} from '@adac/core-view';

import {
  __, Children, UserRoleType, UserRole,
} from '@adac/core-model';

import Header from '../common/Header';

import { UserFormValues } from './UserFormValues';
import {
  FormStyles, FormLayout,
} from '../common/FormStyles';


interface UserFormTypes {
  user: UserFormValues;
  roles: UserRoleType[];
  children?: Children;
  onCancel: () => void;
}

export const UserForm = ({
  children,
  user,
  roles,
  onCancel,
}: UserFormTypes) => {
  const {
    values, isSubmitting, isValid, errors,
  } = useFormikContext<UserFormValues>();

  return (
    <Form>
      <Header goBack={onCancel} title={user?.username || __('New user')} id={user?.id} />
      <FormStyles>
        <FormLayout>

          <ColumnLayout ratio="1fr">
            <Field
              name="role"
              render={({ field }: FieldProps<'role', UserFormValues>) => (
                <MuiTextField
                  {...field}
                  select
                  variant="outlined"
                  inputProps={{ name: 'role', id: 'outlined-role-simple' }}
                >
                  {roles.map(role => (
                    <MenuItem selected={values?.roles?.includes(role)} value={role}>{__(`Role:${role}`)}</MenuItem>
                  ))}
                </MuiTextField>
              )}
            />
          </ColumnLayout>

          <ColumnLayout ratio="1fr 1fr" gap="20px">
            <Field
              name="id"
              render={({ field }: FieldProps<'id', UserFormValues>) => <TextField {...field} disabled badgeEqualsPlaceholder badgeTitle={__('User Id')} />}
            />

            <Field
              name="username"
              render={({ field }: FieldProps<'username', UserFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Username')} />}
            />

          </ColumnLayout>
          {errors.username && <ErrorMessage>{__(errors.username)}</ErrorMessage>}

          <ColumnLayout ratio="1fr">
            <Field
              name="name"
              render={({ field }: FieldProps<'name', UserFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Name')} />}
            />
          </ColumnLayout>
          {errors.name && <ErrorMessage>{__(errors.name)}</ErrorMessage>}


          {values.role !== UserRole.API && (
            <ChangeMFAPhoneFields />
          )}
          {errors.password && <ErrorMessage>{__(errors.password)}</ErrorMessage>}

          {values.role !== UserRole.API && (
            <ColumnLayout ratio="1fr 1fr">
              <Field
                name="password"
                render={({ field }: FieldProps<'password', UserFormValues>) => <TextField {...field} type="password" badgeEqualsPlaceholder badgeTitle={__('Password')} />}
              />
              <Field
                name="confirmPassword"
                render={({ field }: FieldProps<'confirmPassword', UserFormValues>) => <TextField {...field} type="password" badgeEqualsPlaceholder badgeTitle={__('Confirm password')} />}
              />
            </ColumnLayout>
          )}
          {errors.password && <ErrorMessage>{__(errors.password)}</ErrorMessage>}


          <ColumnLayout ratio="1fr 1fr">
            <FullWidthLayout style={{ width: 'auto' }}>
              <Button
                type="button"
                info
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={() => onCancel()}
              >
                <ButtonTitle>{__('Cancel')}</ButtonTitle>
              </Button>
            </FullWidthLayout>

            <FullWidthLayout>
              <Button
                type="submit"
                cta
                disabled={isSubmitting || !isValid}
                isLoading={isSubmitting}
              >
                <ButtonTitle>{__('Update')}</ButtonTitle>
              </Button>
            </FullWidthLayout>
          </ColumnLayout>

          {children && (
            <ColumnLayout ratio="1fr">
              {children}
            </ColumnLayout>
          )}

        </FormLayout>
      </FormStyles>
    </Form>
  );
};
