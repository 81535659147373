import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { showNotification as showNotificationAction, userLogin as useLoginAction } from 'react-admin';

import {
  Page, ErrorMessage, LocalizableError,
  SubPage,
  useFormikAuth,
  Text,
  ConfirmTokenForm,
  LoginPage as LoginPageComponent,
  LoginHeader,
  // useLocation,
  useLocation,
} from '@adac/core-view';
import {
  __,
  confirmToken,
  requestMFATokenKey,
} from '@adac/core-model';

// import { connect } from 'react-redux';
import { observer } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { withRouter } from 'react-router';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
// import { withRouter } from 'react-router';
import { useAuthStore /* useOverlay */ } from '../../stores';
import { PathNames } from './LoginRouterContainer';
// import { FormProps, RARecordProps } from '../common/react-admin/interfaces';



export const LayoutStyles = styled(Page)`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 450px;
  position: relative;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  border-radius: 2px;

  
  section {
    padding: 0;
  }

  fieldset {
  }
`;


const LoginPage = observer((/* props: RouteComponentProps */) => {
  const [overlay, setOverlay] = useState<string | JSX.Element>('');
  const { goTo } = useLocation();
  const { url, setToken, deleteToken } = useAuthStore();

  const onError=(error: LocalizableError) => {
    if (error.message.match(/(Too many attempts)|(expired)/)) setTokenKey('');
    setOverlay(<ErrorMessage>{__(error.toLocalizedString ? error.toLocalizedString() : error.toString())}</ErrorMessage>);
  };

  const { onSubmit: onStartLogin, result: tokenKey, setResult: setTokenKey } = useFormikAuth(requestMFATokenKey(url), onError, () => setOverlay(''));

  const { onSubmit: onConfirmSubmit, result: jwtToken } = useFormikAuth(confirmToken, onError);
  useEffect(() => {
    if (jwtToken) setToken(jwtToken || '');
    else deleteToken();
  }, [jwtToken, setToken, deleteToken]);

  if (tokenKey) {
    return (
      <LayoutStyles padding="24px">
        <LoginHeader marginLeft="-24px" marginTop="-24px" marginRight="-24px" />
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirmSubmit} onCancel={() => setTokenKey('')}>
          <SubPage>
            <br />
            <Text>{__('Confirm token for Admin login')}</Text>
          </SubPage>
          {overlay}
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <LoginPageComponent onSubmit={onStartLogin} onForgot={() => goTo(PathNames.FORGOT_PASSWORD)}>
        <LoginHeader />
        {overlay}
      </LoginPageComponent>
    </LayoutStyles>
  );
});


export default LoginPage;

// const ConnectedLoginPage = connect(undefined, {
//   showNotification: showNotificationAction,
//   userLogin: useLoginAction,
// })(withRouter(LoginPage));

// export default (raProps: any) => (
//   <ConnectedLoginPage {...raProps} />
// );

