/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Edit, getResources, DELETE, UPDATE, showNotification as showNotificationAction,
} from 'react-admin';

import {
  Formik, FormikHelpers as FormikActions,
  FormikProps,
} from 'formik';

import { connect } from 'react-redux';

import {
  getApiRoutes, UserRoleListBackoffice, __,
} from '@adac/core-model';

import dataProvider from '../../dataProvider';

import { USERS_BACKOFFICE } from '../../dataProvider/resources';
import { UserForm } from './UserForm';

import { UserFormValues } from './UserFormValues';
import {
  DeleteButton,
} from '../common/DeleteButton';
import { UserFormSchema } from './UserFormSchema';
import { FormProps } from '../common/react-admin/interfaces';


const UserEditForm = (raProps: FormProps) => {
  const [roles] = useState(UserRoleListBackoffice);

  const user = raProps.record;

  const deleteUser = async ({ setSubmitting }: FormikProps<UserFormValues>) => {
    setSubmitting(true);
    try {
      await dataProvider(DELETE, USERS_BACKOFFICE, { id: user.id });
      raProps.history.push(getApiRoutes(false, '').backoffice.users.list);
    } catch (error) {
      raProps.showNotification(__(error.toString()), 'warning');
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit = async (values: UserFormValues, actions: FormikActions<UserFormValues>) => {
    const editedUser = {
      id: values.id,
      name: values.name,
      username: values.username,
      phone: values.phone,
      role: values.role,
      ...(values.password ? { password: values.password, confirmPassword: values.confirmPassword } : {}),
    };
    try {
      await dataProvider(UPDATE, raProps.resource, { id: user.id, data: editedUser });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').backoffice.users.list);
    } catch (error) {
      raProps.showNotification(__(error.toString()), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };


  return (
    <Formik
      initialValues={{
        ...user,
        password: '',
        confirmPassword: '',
      }}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<UserFormValues>) => (
        <UserForm
          user={user}
          roles={roles}
          onCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.users.list)}
        >
          <DeleteButton
            isLoading={props.isSubmitting}
            disabled={props.isSubmitting}
            onClick={() => deleteUser(props)}
          />
        </UserForm>
      )}
    </Formik>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedUserEditForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(UserEditForm);

export default (raProps: any) => (
  <Edit {...raProps}>
    <ConnectedUserEditForm {...raProps} />
  </Edit>
);
