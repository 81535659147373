/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  List, Datagrid, TextField,
} from 'react-admin';

import { __ } from '@adac/core-model';

import UserFilter from './UserFilter';
import { GenericListActions } from '../common/GenericListActions';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';

export default (props: any) => (
  // bulkActionButtons contains stuff like the dlete checkbox
  // actions contains the export csv button and functionality
  <List
    {...props}
    bulkActionButtons={false}
    actions={<GenericListActions />}
    filters={<UserFilter />}
  >
    <Datagrid>
      <TextField source="id" label={__('User id')} /* sortable={false} */ />
      <TextField source="name" label={__('Name')} /* sortable={false} */ />
      <TextField source="username" label={__('Username')} /* sortable={false} */ />
      <TextField source="roleName" label={__('Role')} sortable={false} />
      <TextField source="phone" label={__('mfaPhone')} sortable={false} />
      <TextField source="lastLogin" label={__('lastLogin')} sortable />
      <TextField source="passwordUpdatedAt" label={__('passwordUpdatedAt')} />
      {/* <TextField source="status" label={__('Status')} /> */}
      <EditButtonWithRole label={__('Edit')} />
    </Datagrid>
  </List>
);
