import React from 'react';
import {
  CardActions, CreateButton, RefreshButton, ExportButton,
} from 'react-admin';
import { __ } from '@adac/core-model';
import { useIsUserReadOnly } from '../../helpers/useUserRole';

interface GenericActionProps {
  [key: string]: any;
}

interface GenericListActions extends GenericActionProps{
  allowCreate: boolean;
}

export const GenericListActions = ({
  basePath,
  currentSort,
  exporter,
  filters,
  filterValues,
  resource,
  total,
  showFilter,
  displayedFilters,
  allowCreate = true,
}: GenericActionProps) => {
  const readonly = useIsUserReadOnly();
  return (
    <CardActions>
      {filters && React.cloneElement(filters, {
        resource, showFilter, displayedFilters, filterValues, context: 'button',
      })}
      {allowCreate && !readonly ? <CreateButton basePath={basePath} label={__('Add')} /> : null}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        label={__('Export')}
      />
      <RefreshButton label={__('Refresh')} />
    </CardActions>
  );
};
