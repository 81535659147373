/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Create, getResources, showNotification as showNotificationAction, CREATE,
} from 'react-admin';
import { connect } from 'react-redux';

import { ManageDriver } from '@adac/core-view';
import { Driver, getApiRoutes, __ } from '@adac/core-model';

import Header from '../common/Header';
import CardContent from './CardContent';
import dataProvider from '../../dataProvider';
import { useRouterParamCompanyId } from '../../helpers/useRouterParam';
import { FormProps } from '../common/react-admin/interfaces';


const DriverCreateForm = ({ showNotification, ...raProps }: FormProps<Driver>) => {
  const companyId = Number(useRouterParamCompanyId(raProps));

  const goBack = () => raProps.history.push(getApiRoutes(false, '').companyAdmin.editDriversList(companyId));

  const onSubmit = async (driverParam: any) => {
    try {
      await dataProvider(CREATE, raProps.resource, { data: driverParam });
      goBack();
    } catch (error) {
      showNotification(__(error.toString()));
    }
  };

  return (
    <>
      <Header goBack={goBack} title={__('New Driver')} />
      <CardContent>
        <ManageDriver
          companyId={companyId}
          onSubmit={onSubmit}
          onBack={goBack}
        />
      </CardContent>
    </>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedDriverCreateForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(DriverCreateForm);

export default (raProps: any) => (
  <Create {...raProps}>
    <ConnectedDriverCreateForm {...raProps} />
  </Create>
);
