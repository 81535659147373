import { Company, getApiRoutes, request } from '@adac/core-model';
import { stores } from '../stores';


export function requestCompanyList() {
  const url = getApiRoutes().companyAdmin.list;
  return request<Company[]>(url, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${stores.auth.token || ''}`,
  });
}
export function requestActiveCompanyList() {
  const url = getApiRoutes().companyAdmin.listActive;
  return request<Company[]>(url, 'GET', {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${stores.auth.token || ''}`,
  });
}
