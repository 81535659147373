/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Create, CREATE, getResources, showNotification as showNotificationAction,
} from 'react-admin';

import { Formik, FormikHelpers as FormikActions } from 'formik';
import { connect } from 'react-redux';

import { getApiRoutes, UserRoleListBackoffice, __ } from '@adac/core-model';
import { UserForm } from './UserForm';

import dataProvider from '../../dataProvider';

import { UserFormValues } from './UserFormValues';
import { FormProps } from '../common/react-admin/interfaces';
import { CreateUserFormSchema } from './UserFormSchema';


const UserCreateForm = (raProps: FormProps) => {
  const [roles] = useState(UserRoleListBackoffice);
  const user = raProps.record;

  const onSubmit = async (values: UserFormValues, actions: FormikActions<UserFormValues>) => {
    const newUserData = {
      name: values.name,
      phone: values.phone,
      username: values.username || null,
      role: values.role,
      ...(values.password
        ? { password: values.password, confirmPassword: values.confirmPassword }
        : { password: null, confirmPassword: null }),
    };
    try {
      await dataProvider(CREATE, raProps.resource, { data: newUserData });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').backoffice.users.list);
    } catch (error) {
      raProps.showNotification(__(error.toString()), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        ...user,
        password: '',
        confirmPassword: '',
      }}
      validationSchema={CreateUserFormSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      <UserForm
        user={user}
        roles={roles}
        onCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.users.list)}
      />
    </Formik>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedUserCreateForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(UserCreateForm);

export default (raProps: any) => (
  <Create {...raProps}>
    <ConnectedUserCreateForm {...raProps} />
  </Create>
);
