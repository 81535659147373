/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  // UPDATE,
  // DELETE,
  // GET_MANY,
  // GET_MANY_REFERENCE,
} from 'react-admin';

import {
  flattenCompanyFieldsWithBoss, flattenCompanyList, flattenSupportedZipList,
} from '@adac/core-view';
import {
  VALIDATION_ERROR_CODE, UNAUTHORIZED_ERROR_CODE, FORBIDDEN_ERROR_CODE, PAGE_NOT_FOUND_CODE, SERVER_ERROR_CODE,
  getNumber, __, HolidayRecord, config,
  NetworkError, ClientValidationError, ValidationErrorType, BasicErrorType, getFullName, VERSION_MISMATCH_ERROR_CODE, AdminLogRecord,
  getClaimReference,
  CompanyWithOrderAndSuggestion,
} from '@adac/core-model';

import moment from 'moment';
import { stores } from '../stores';

import {
  USERS_BACKOFFICE,
  OPEN_CASES,
  CLOSED_CASES,
  SND_COMPANIES,
  BENCHMARK,
  ZIPCODES,
  SETTINGS,
  NEW_CASE,
  HOLIDAYS,
  DRIVERS,
  SND_COMPANY_DRIVERS,
  ADMIN_LOGS,
} from './resources';

const handlerErrorCodes = async (response: any, status: number, allowForbiddenToStayLoggedin: boolean) => {
  let error: any;


  const data = await response.json();
  error = new NetworkError({ status, data });

  console.debug('status', status);
  if ([FORBIDDEN_ERROR_CODE, UNAUTHORIZED_ERROR_CODE, VERSION_MISMATCH_ERROR_CODE].includes(status)) {
    // error = new Error('Forbidden')
    // console.log('might need to log in', allowForbiddenToStayLoggedin, [UNAUTHORIZED_ERROR_CODE, VERSION_MISMATCH_ERROR_CODE].includes(status));
    if (!allowForbiddenToStayLoggedin && [UNAUTHORIZED_ERROR_CODE, VERSION_MISMATCH_ERROR_CODE].includes(status)) {
      stores.auth.deleteToken();
    }
  } else if (status === VALIDATION_ERROR_CODE) {
    try {
      const firstValidationError: ValidationErrorType = (data as ValidationErrorType[])[0];
      const { field, message } = firstValidationError;
      error = new ClientValidationError(field?.error || message, field?.name, Number(field?.limit) || undefined, field?.zipcodeList);
    } catch (e) {
      console.log('Validation message cannot be decoed');
    }
  } else if (status === PAGE_NOT_FOUND_CODE || status === SERVER_ERROR_CODE) {
    const firstError: BasicErrorType = (data as BasicErrorType[])[0];
    error = new Error(firstError?.message || '404 not found');
  } else {
    console.log('NetworkError');
  }
  error.status = status;

  throw error;
};

// TODO: generic typing for return value?
export async function handleResponseStatus(response: any, allowForbiddenToStayLoggedin = false) {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return response.json();
  }
  return handlerErrorCodes(response, status, allowForbiddenToStayLoggedin);
}

const handleResponseStatusWithTotal = async (response: any, type: string, allowForbiddenToStayLoggedin = false) => {
  const { status, headers } = response;
  // headers.forEach(console.log);
  if (status >= 200 && status < 300) {
    if (!headers.has('Content-Range') && type === GET_LIST) {
      headers.forEach(console.log);
      throw new Error(
        'The Content-Range header is missing in the HTTP Response.',
      );
    }
    const json = await response.json();
    const result: { data: any; total?: number } = {
      data: json,
    };
    if (type === GET_LIST) {
      result.total = parseInt(
        headers
          .get('Content-Range')
          .split('/')
          .pop(),
        10,
      );
    }
    return result;
  }
  return handlerErrorCodes(response, status, allowForbiddenToStayLoggedin);
};

export const getCommissioningReferenceFromAdminLogData = (payload: any) => payload?.Data?.Entity?.Request?.CommissioningReference || payload?.commissioningReference;


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleResponseDataForGetList = (resource: string, response: any, _resourceParams?: any) => {
  switch (resource) {
    case USERS_BACKOFFICE:
      return {
        total: response.total,
        data: response.data.map((user: any) => ({
          ...user,
          lastLogin: user.lastLogin && moment(user.lastLogin).format('DD-MM-YYYY HH:mm'),
          passwordUpdatedAt: user.passwordUpdatedAt && moment(user.passwordUpdatedAt).format('DD-MM-YYYY HH:mm'),
          role: user.roles.join(', '),
          roleName: __(`Role:${user.roles.join(', ')}`),
        })),
      };
    case OPEN_CASES:
      // eslint-disable-next-line no-case-declarations
      return {
        total: response.total,
        data: response.data.map((caseObj: any) => ({ // TODO: special new typing similar to CasRecord!
          id: caseObj.id,
          token: caseObj[`${process.env.REACT_APP_CASE_ID_DISPLAY || 'id'}`],
          customerName: getFullName(caseObj),
          customerPhone: `${caseObj.customerPhone}`,
          productType: __(`ProductType:short:${caseObj.productType}` || 'unkown'),
          commissioningReference: `${getClaimReference(caseObj.commissioningReference)}`,
          customerAddress: `${caseObj.customerAddressStreet} ${caseObj.customerAddressNumber}, ${caseObj.customerAddressZip} ${caseObj.customerAddressCity}`, // TODO: we shall use createAddressString, but that uses different props, without customer prefix
          customerZipCode: `${caseObj.customerAddressZip}`,
          timeOfOrder: config.time(caseObj.createdAt),
          complexity: __(`complexity:${caseObj.complexity}`),
          infoCaseType: __(`${caseObj.infoCaseType}`),
          companyId: `${caseObj.companyId}`,
          companyName: `${caseObj.companyName}`,
          driverId: `${caseObj.driverId}`,
          driverName: `${caseObj.driverName}`,
          description: `${caseObj.description}`,
          // INFO case doesn't gave `currentState`!
          currentStatus: caseObj?.currentState?.event,
          currentStatusText: __(`status:${caseObj?.currentState?.event}`),
          currentStatusTime: caseObj?.currentState?.createdAt ? config.time(caseObj?.currentState?.createdAt) : config.time(caseObj.createdAt),
        })),
        // .sort((a: any, b: any) => b.id-a.id),
      };
    case CLOSED_CASES:
      return {
        total: response.total,
        data: response.data.map((caseObj: any) => ({ // TODO: special new typing similar to CasRecord!
          id: caseObj.id,
          token: caseObj[`${process.env.REACT_APP_CASE_ID_DISPLAY || 'id'}`],
          customerName: getFullName(caseObj),
          customerPhone: `${caseObj.customerPhone}`,
          commissioningReference: `${getClaimReference(caseObj.commissioningReference)}`,
          productType: __(`ProductType:short:${caseObj.productType}` || 'unkown'),
          customerAddress: `${caseObj.customerAddressStreet} ${caseObj.customerAddressNumber}, ${caseObj.customerAddressZip} ${caseObj.customerAddressCity}`, // TODO: we shall use createAddressString, but that uses different props, without customer prefix
          customerZipCode: `${caseObj.customerAddressZip}`,
          complexity: __(`complexity:${caseObj.complexity}`),
          companyName: `${caseObj.companyName}`,
          driverName: `${caseObj.driverName}`,
          timeOfOrder: config.time(caseObj.createdAt),
          drivingTime: caseObj.driverTimeToArrive && `${Math.round(getNumber(caseObj.driverTimeToArrive) / 60 / 1000)}min`,
          firstSignature: config.time(`${caseObj.signedOpenComissionAt}`),
          lastSignature: config.time(`${caseObj.signedJobFInished}`),
          commissionReport: caseObj.commissionReport,
          commissionExtraDamage: caseObj.commissionExtraDamage,
          finalStatusReport: caseObj.finalStatusReport,
          invoiceDocument: caseObj.invoiceDocument || undefined,
          cancelled: __(`cancelled:${caseObj.cancelled || 'no'}`),
          price: `€${caseObj.totalAmount}`,
        })),
        // .sort((a: any, b: any) => b.id-a.id),
      };
    case DRIVERS:
    case SND_COMPANY_DRIVERS:
      return {
        total: response.total,
        data: response.data.map((data: any) => ({
          ...data,
          role: data.user && data.user.roles ? __(data.user.roles[0].name) : __('Driver'),
        })),
      };
    case SND_COMPANIES:
      return {
        total: response.total,
        data: flattenCompanyList(response.data),
      };
    case HOLIDAYS:
      return {
        total: response.total,
        data: response.data.map((data: HolidayRecord) => ({
          ...data,
          regionName: data.region?.name || __('All region'),
          year: data.year ? data.year : __('Every year'),
        })),
      };
    case BENCHMARK:
      return {
        total: response.total,
        data: response.data.countsByMonth.map((benchmark: any) => ({
          id: `${benchmark.month}-${benchmark.status}`,
          month: benchmark.month,
          status: benchmark.status,
          allClosedOnlyCases: benchmark.allClosedOnlyCases,
          takenBySWA: benchmark.takenBySWA,
          succesfulSWAAction: benchmark.succesfulSWAAction,
          SWAOpeningFailed: benchmark.SWAOpeningFailed,
        }))
          .concat({
            id: 'total',
            month: __('Total'),
            status: '',
            allClosedOnlyCases: response.data.countsSum.allClosedOnlyCases,
            takenBySWA: response.data.countsSum.takenBySWA,
            succesfulSWAAction: response.data.countsSum.succesfulSWAAction,
            SWAOpeningFailed: response.data.countsSum.SWAOpeningFailed,
          }),
      };
    case ADMIN_LOGS:
      return {
        total: response.total,
        data: response.data.map((adminLog: AdminLogRecord) => ({
          id: adminLog.id,
          // commissioningReference: formatCommissioning(getCommissioningReferenceFromAdminLogData(adminLog.data)),
          createdAt: moment(adminLog.createdAt).format('DD-MM-YYYY HH:mm'),
          data: adminLog.data,
          changed: adminLog.data?.changed,
          entity: adminLog.entity,
          entityId: adminLog.entityId,
          status: adminLog.status,
          response: adminLog.response,
          origin: adminLog.origin,
          url: adminLog.url,
          action: __(`httpAction:${adminLog.action}`),
          userId: adminLog.userId,
          userName: adminLog.user?.name,
        })),
      };
    case ZIPCODES:
      return {
        total: response.total,
        data: flattenSupportedZipList(response.data),
      };

    // shall we move under default?
    case SETTINGS:
      return response;
    default:
      return null;
  }
};

const handleResponseDataForGetOne = (resource: string, response: any) => {
  switch (resource) {
    case USERS_BACKOFFICE:
      return {
        data: { ...response.data, role: response.data.roles.join(', ') },
      };
    case SND_COMPANIES:
      return {
        data: flattenCompanyFieldsWithBoss(response.data as CompanyWithOrderAndSuggestion),
      };
    case ADMIN_LOGS:
      return {
        data: {
          ...response.data,
          action: __(`httpAction:${response.data.action}`),
        },
      };
    default:
      console.warn('WARN: Default handleResponse ForGetOne, might need to implement!', response);
      return response;
  }
};

const handleResponseDataForCreate = (resource: string, response: any) => {
  switch (resource) {
    case NEW_CASE:
    case OPEN_CASES:
      return { data: response.data.case };
    case CLOSED_CASES:
      return { data: response.data };
    default:
      return null;
  }
};

interface ReactAdminData {
  data: any;
  total: number | null | undefined;
}

const handleResponseData = (response: any, resource: string, type: string, resourceParams?: any): ReactAdminData => {
  let result: any;
  switch (type) {
    case GET_LIST:
      result = handleResponseDataForGetList(resource, response, resourceParams);
      break;
    case GET_ONE:
      result = handleResponseDataForGetOne(resource, response);
      break;
    case CREATE:
      result = handleResponseDataForCreate(resource, response);
      break;
    default:
      result = response;
  }

  return result;
};

// eslint-disable-next-line no-undef
export const fetchResource = (type: string, url: string, resource: string, options: any, resourceParams?: any) => fetch(url, options)
  .then(response => handleResponseStatusWithTotal(response, type))
  .then(response => handleResponseData(response, resource, type, resourceParams));


// Other cases for reference:
// case UPDATE_MANY:
//     const query = {
//         filter: JSON.stringify({ id: params.ids }),
//     };
//     url = `${apiUrl}/${resource}?${stringify(query)}`;
//     options.method = 'PATCH';
//     options.body = JSON.stringify(params.data);
//     break;
// case DELETE_MANY:
//     const query = {
//         filter: JSON.stringify({ id: params.ids }),
//     };
//     url = `${apiUrl}/${resource}?${stringify(query)}`;
//     options.method = 'DELETE';
//     break;
// case GET_MANY: {
//     const query = {
//         filter: JSON.stringify({ id: params.ids }),
//     };
//     url = `${apiUrl}/${resource}?${stringify(query)}`;
//     break;
// }
// case GET_MANY_REFERENCE: {
//   // const { page, perPage } = params.pagination;
//   // const { field, order } = params.sort;
//   // const query = {
//   //   sort: JSON.stringify([field, order]),
//   //   range: JSON.stringify([
//   //     (page - 1) * perPage,
//   //     page * perPage - 1,
//   //   ]),
//   //   filter: JSON.stringify({
//   //     ...params.filter,
//   //     [params.target]: params.id,
//   //   }),
//   // };
//   url = `${apiUrl}/${resource}/${params.id}}`;
//   break;
// }
